@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@300;400;500;700&family=Oxygen:wght@300;400;700&display=swap'); */

body {
  margin: 0;
  font-family: 'Oxygen', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

h4 {
  margin: 0px
}

@media screen and (max-width: 991px) {
  .mainlogo {
    width: 80vw
  }
  .hideonmobile {
    display: none
  }
  .headline {
    font-size: 36px;
    line-height: 42px;
  }
  .marginAdaptive {
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media screen and (min-width: 992px) {
  .mainlogo {
    width: 30vw
  }
  .hideonmobile {
    display: block
  }
  .headline {
    font-size: 42px;
    line-height: 72px;
  }

  p {
    font-size: 18px
  }

  /* .marginAdaptive {
    
  } */

  .paddingAdaptive {
      padding-left: 10vw; 
      padding-right: 10vw
  }

  
}

.slick-slide {
	margin: 24px;
}

 /* .slick-slide {
	margin: 12px;
}

.slick-list {
	margin: -12px;

} */

