@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";

/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@300;400;500;700&family=Oxygen:wght@300;400;700&display=swap'); */

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: 'Oxygen', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
